.player {
  padding: 5px 0;
  display: flex;
  align-items: center;
}

.ownPlayer {
  background: rgba(0, 0, 256, 0.1);
}

.playerUsername {
  margin: 0 8px;
}

.playerSeparator {
  height: -1px;
  border: 1px solid #d0d0d0;
}

.lobbyPlayerElo {
  margin-left: auto;
  margin-right: 5px;
}
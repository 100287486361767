.tablePaper {
  max-width: 800px;
  margin: auto;
}

.positionCell {
  width: 50px;
}

.rankIconCell {
  width: 36px;
}

.userEntry {
  display: flex;
  align-items: center;
}
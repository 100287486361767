.mapSelectionMenu {
  margin-bottom: 16px;
}

.mapSelectionMenuContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mapSelectionMenuItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 10px;
}

.mapSelectionMenuComponent {
  width: 150px;
  margin: 0 10px;
}

.mapSelectionButton {
  margin-top: 16px;
}
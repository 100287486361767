html {
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  background: #f9f9f9;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  background: #f9f9f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 36pt;
}

.shadowBox {
  box-shadow: 4px 4px 10px black !important;
}

.shadowText {
  text-shadow: 4px 4px 10px black !important;
}

.transparentBackground {
  background: rgba(256, 256, 256, 0.9) !important;
}
@import '../../styles/common';

.home {
  text-align: center;
  width: 100%;
}

.title {
  margin-bottom: 70px;
  color: white;
}

.homeComponent {
  margin: 50px 0;
}

.homeHeading {
  color: white;
}

.rankDisplayName {
  margin-top: -0.6em;
}
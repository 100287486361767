.nextToIconLabel {
  margin-left: 6px;
}

.ongoingGame {
  line-height: 1.5;
  height: 220px !important;
  display: flex;
  flex-direction: column;
}

.ongoingGamePlayersPreview {
  margin: 10px;
}

.ongoingGameSpace {
  flex: 1 1 auto;
}

.ongoingGameRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
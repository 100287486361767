.applyButtonCard {
  margin: 30px 0;
}

.applyButton {
  margin: 10px 20px;
  text-align: right;
}

.settingsContent {
  display: flex;
  margin: 30px 10px;
  justify-content: center;
  align-items: center;
}

.settingsUsername {
  margin: 0 20px;
}
@media (min-width: 960px) {
  .scoreboard {
    height: 710px;
  }

  .totalScoreboard {
    max-height: 300px;
    overflow: auto;
  }

  .roundScoreboard {
    max-height: 300px;
    overflow: auto;
  }
}

.nextRoundButton {
  text-align: center;
}

.thisRoundTitle {
  margin-top: 15px;
  text-align: center;
}
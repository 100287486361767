.highcharts-graph.zone-wood {
  stroke: saddlebrown;
}

.highcharts-area.zone-wood {
  fill: saddlebrown;
}

.highcharts-area.zone-bronze {
  stroke: coral;
}

.highcharts-area.zone-bronze {
  fill: coral;
}

.highcharts-area.zone-silver {
  stroke: silver;
}

.highcharts-area.zone-silver {
  fill: silver;
}

.highcharts-area.zone-gold {
  stroke: gold;
}

.highcharts-area.zone-gold {
  fill: gold;
}

.highcharts-area.zone-platinum {
  stroke: lightslategray;
}

.highcharts-area.zone-platinum {
  fill: lightslategray;
}

.highcharts-area.zone-diamond {
  stroke: deepskyblue;
}

.highcharts-area.zone-diamond {
  fill: deepskyblue;
}

.highcharts-area.zone-master-guesser {
  stroke: blue;
}

.highcharts-area.zone-master-guesser {
  fill: blue;
}

.highcharts-area.zone-supreme-guesser {
  stroke: mediumvioletred;
}

.highcharts-area.zone-supreme-guesser {
  fill: mediumvioletred;
}

.highcharts-area.zone-supreme-guesser {
  stroke: black;
}

.highcharts-area.zone-supreme-guesser {
  fill: black;
}


.scoreboardEntry {
  padding: 0 4px;
}

@media screen and (min-width: 960px) {
  .gameScoreboard {
    height: 630px;
  }
}

.gameScoreboard {
  max-height: 630px;
  overflow: auto;
}

.openMakeGuessButton {
  text-align: center;
}

.gameStatus {
  margin-bottom: 20px;
}

.gameStatusContent {
  padding: 16px;
}
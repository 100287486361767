.playersPreview {
  display: flex;
  text-align: left;
}

.playersPreviewPosition {
  margin: 0 3px 0 0;
}

.playersPreviewPicture {
  margin: 0 3px;
}

.playersPreviewUsername {
  margin: 0 0 0 3px;
}
.homeTitle {
  color: white;
}

.pastGamesPagination {
  margin-top: 20px;
}

.pastGameDiv {
  padding: 4px;
}

.noGamesDiv {
  display: flex;
  width: 100%;
  justify-content: center;
  color: white;
}
.player {
  margin-top: 5px;
  display: flex;
  align-content: stretch;
  align-items: center;
}

.positionIndicator {
  margin: 0 8px;
}

.playerUsername {
  margin: 0 8px;
}

.playerSeparator {
  margin-top: 5px;
  height: -1px;
  border: 1px solid #d0d0d0;
}
.gameSettingsGuest {
  width: 220px;
  height: 450px;
  margin: auto;
}

.gameSettingsGuestCard {
  max-width: 400px;
  margin: 0 auto;
}

.gameSettingsGuestRow {
  padding: 5px 0;

  input {
    width: 220px !important;
  }
}

.gameSettingsGuestSwitchRow {
  margin: -10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.gameSettingsGuestRowMap {
  padding: 5px 0;
}

.gameSettingsGuestValue {
  font-size: 13pt;
  padding: 8px 2px;
}

.gameSettingsGuestMap {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  width: 220px;
}

.gameSettingsGuestMapInfoButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 6px 0;
}

.gameSettingsGuestMapInfoButton {
  width: 40px !important;
  min-width: 40px !important;
}
.gameSettingsHost {
  width: 220px;
  height: 450px;
  margin: auto;
}

.gameSettingsHostCard {
  max-width: 400px;
  margin: 0 auto;
}

.gameSettingsHostRow {
  padding: 5px 0;

  input {
    width: 220px !important;
  }
}

.gameSettingsHostRowMap {
  padding: 5px 0;
  width: 180px;
}

.gameSettingsGuestSwitchRow {
  margin: -10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.gameSettingsHostMap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 220px;
}

.gameSettingsHostMapInfoButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.gameSettingsHostMapInfoButton {
  width: 40px !important;
  min-width: 40px !important;
}
@import '../../styles/common';

.lobbyControl {
  display: flex;
  justify-content: space-between;
  margin: 100px 40px 0 40px;
}

.inviteLink {
  display: flex;
  text-align: center;
  //margin: 0 auto 40px auto;
}

.inviteLinkCard {
  width: 800px !important;
  margin: 0 auto 36px auto;
}

.inviteLinkCardContent {
  padding: 10px !important;
}

.inviteLinkTextField {
  width: 720px;
}

.copyToClipboardButton {
  margin-top: 6px;
}
.playerTableCell {
  display: flex;
  align-items: center;
}

.positionLabel {
  margin-right: 6px;
}

.usernameLabel {
  margin-left: 6px;
}

.eloWon {
  color: green;
}

.eloLost {
  color: red;
}

.rankIconCell {
  width: 36px;
}

.extraPoints {
  color: green;
}

.roundSummaryTableCell {
  display: flex;
  align-items: center;
}

.roundSummaryTableCountryFlag {
  margin: 0 4px;
}
.gameSummaryMap {
  margin-bottom: 15px;
}

.gameSummaryTable {
  margin: 15px 0;
}

.gameSummaryTableContent {
  overflow: auto;
}

.homeButton {
  margin-top: 15px;
  text-align: center;
}
.App {
  text-align: center;
}

.appBackground {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-attachment: fixed;
}

.backgroundLayer {
    background-color: rgba(64, 64, 64, 0.6);
    width: 100%;
    height: 100%;
    min-height: 100vh;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.newGameProgress {
  position: absolute;
  display: flex;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
}

.appContentArea {
  margin-left: 162px;
  padding: 100px 20px 10px 20px;
}

.homeDiv {
  margin-top: 180px;
}

.appDrawerContainer {
  &ul {
    margin-top: 64px;
  }
}